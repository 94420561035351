<template>
  <l-polyline :latLngs="myPath" :color="color" :weight="weight" />
</template>

<script>
import { LPolyline } from 'vue2-leaflet';
import { $decodePath } from '../helper';

export default {
  components: {
    LPolyline
  },
  computed: {
    myPath() {
      if (this.encodedPath) return $decodePath(this.encodedPath);
      if (this.path) return this.path;
      return [];
    }
  },
  props: {
    color: { type: String, default: '#026C75' },
    encodedPath: { type: String, default: '' },
    path: { type: Array, default: () => [] },
    weight: { type: Number, default: 3 }
  }
};
</script>
